import { DELIVEREE_AUTHENTICATION_SERVICE_URL } from 'constants/appConstants'
import StorageKeys from 'constants/storage-keys'
import apiClient from 'services/axiosApp'

let originDomain = window.location.origin
if (['lex.deliveree.com', 'localhost'].includes(window.location.hostname)) {
  originDomain = 'https://webapp.test.deliveree.com'
}

const CustomerAPI = {
  signIn: async (params) => apiClient.post(`${DELIVEREE_AUTHENTICATION_SERVICE_URL}/api/v2/customers/sign_in`, {
    country_code: params.countryCode,
    login: params.login,
    password: params.password,
  }),
  signInFTL: async (params) => apiClient.post(`${originDomain}/customers/sign_in`, {
    authenticity_token: 'esqPionoxa4goLHMIwscjEuSYwHdeyzjrXHfjMkJR7s6XctFu0pXnyMXzNGo1OkZ818uDr8Rkh3RcKCQ0i1tog==',
    customer: {
      country_code: params.countryCode,
      login: params.login,
      password: params.password,
    }
  },
    {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
  ),
  signOut: () => apiClient.get(`${originDomain}/customers/sign_out`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  }),
  switchAccount: async (companyId) => {
    try {
      const res = await apiClient.put(`${originDomain}/business/switch_account`, {
        id: companyId,
      }, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      if (res?.data?.object) {
        return {
          ...res.data.object,
          statusApi: res.statusApi
        }
      }
    } catch (err) {
      throw new Error(err)
    }
  },
  reConvertToken: () => apiClient.get(`${originDomain}/bookings/re_convert_token`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  }),
  updateAuthenticationToken: async () => {
    const token = window.localStorage.getItem(StorageKeys.ACCESS_TOKEN) || ''
    return apiClient.get(`${originDomain}/customers/get_authentication_token`, { params: { access_token: token } }, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
  },
  resetPassword: params => apiClient.post('/api/v3/customers/password', {
    login: params.login,
  }),
  signUp: params => apiClient.post('/api/v3/customers/sign_up', params),
  trackDevice: async (authenticationToken, deviceID, deviceToken) => {
    const config = {
      headers: {
        'Fcm-Token': deviceToken,
        'Device-Id': deviceID
      },
      params: {
        only: ['language'],
      },
    }
    try {
      const res = await apiClient.get('/api/v3/settings/customers', config)
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  getTwilioChatToken: async (authenticationToken, deviceID) => apiClient.get('/api/v3/chat/customers/token', {
    headers: {
      'Device-Id': deviceID
    }
  }),
  loadBookings: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/customers/me/bookings', { params })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  favoriteDriversAmount: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/assign_driver/favorite_driver_amount', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getAssignableDrivers: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/assign_driver/available_drivers', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getBlockedTimes: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/assign_driver/blocked_times', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  searchDriverByReferralCode: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/assign_driver/search', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  validateSearchDriverToAssign: async (AuthenticationToken, driverID, params, callback) => {
    try {
      const res = await apiClient.post(`/api/v3/assign_driver/${driverID}/validate`, params)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  addFavoriteDriver: async (AuthenticationToken, params) => {
    try {
      const res = await apiClient.put('/api/v3/preference_drivers', params)
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  addFavoriteFleetDriver: async (AuthenticationToken, params) => {
    try {
      const res = await apiClient.post('/api/v3/preference_drivers', params)
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  verifySmsToken: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.post(`/api/v3/customers/verify_sms_token?sms_token=${params.sms_token}`)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  resendSmsToken: async (AuthenticationToken, callback) => {
    try {
      const res = await apiClient.post('/api/v3/customers/resend_sms_token')
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getFavoriteOrBannedDrivers: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/preference_drivers', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  recentDrivers: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/preference_drivers/available_drivers', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  updatePreferenceListDrivers: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.put('/api/v3/preference_drivers/update_preference_list_drivers', params)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  updateCustomer: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.put('/api/v3/customers/me', params)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  updateCustomerHeader: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.put('/api/v3/customers/me', params)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getCustomer: async (AuthenticationToken, params) => {
    try {
      const res = await apiClient.get('/api/v3/customers/me', {
        params,
        headers: {
          ...(AuthenticationToken ? { Authorization: AuthenticationToken } : {}),
        }
      })
      return { ...res?.data?.object, statusApi: res.status }
    } catch (err) {
      throw new Error(err)
    }
  },
  getFirebaseToken: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/customers/get_firebase_token', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getBookings: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/customers/booking_cards', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getFrontPageBooking: (params) => apiClient.get('/api/v3/customers/recent_bookings', { params }),
  getDraftBookings: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/customers/draft_booking_cards', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  deleteDraftBooking: async (AuthenticationToken, draftID, callback) => {
    try {
      const res = await apiClient.delete(`/api/v3/draft_bookings/${draftID}`, {
        params: { id: draftID },
        headers: { "Device-Type": "Web" }
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  postJoinCustomerChat: async (bookingID, AuthenticationToken, callback) => {
    try {
      const res = await apiClient.post('/api/v3/chat/customers/member', { booking_id: bookingID })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getRequirementNotMet: async (driverID, params, AuthenticationToken, callback) => {
    try {
      const res = await apiClient.post(`/api/v3/assign_driver/${driverID}/requirement_not_met`, params)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  addMultipleFavoriteDriver: async (AuthenticationToken, params) => {
    try {
      const res = await apiClient.put('/api/v3/preference_drivers', params)
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  addMultipleFavoriteFleetDriver: async (AuthenticationToken, params) => {
    try {
      const res = await apiClient.post('/api/v3/preference_drivers', params)
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  socialLogin: (params) => {
    const query = {
      email: params.email,
      access_token: params.access_token,
      provider: params.provider,
    }
    return apiClient.post(
      `${DELIVEREE_AUTHENTICATION_SERVICE_URL}/api/v2/customers/login_via_social_account`,
      query
    )
  },
  getFavoriteAmount: async (AuthenticationToken, params) => {
    try {
      const res = await apiClient.get('/api/v3/preference_drivers/favorite_driver_amount', { params })
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  updateNoteLocation: async (AuthenticationToken, locationID, description) => {
    try {
      await apiClient.put(`/api/v3/locations/${locationID}/note`, description)
    } catch (err) {
      throw new Error(err)
    }
  },
  getCreditAmount: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/credit_accounts/cashback_credit_amount', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getCurrentCustomerProps: (AuthenticationToken, params) => {
    return apiClient.get('/api/v3/batches/current_customer_props', { params })
  },
  getBookingEarliest: async (params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/customers/me/booking/earliest', { params })
      return callback(res?.data?.object || {})
    } catch (err) {
      throw new Error(err)
    }
  },
  getCustomerBooking: async (bookingId, params) => {
    const res = await apiClient.get(`/api/v3/customers/bookings/${bookingId}`, {
      params
    })
    return res?.data?.object
  },
  postCalculateCashbackPercent: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.post('/api/v3/credit_accounts/cashback_credit_earn', params)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getHotlineCurrentCustomer: (params = {}) => apiClient.get('/api/v3/freshchat/hotline_info', { params }),
  updateFreshChatCustomer: (param) => apiClient.put('/api/v3/freshchat/customer', param),
  // is_BP and post payment then return casback else return credit
  getCreditBalance: (params) =>  apiClient.get('/api/v3/credit_accounts/get_wallets', { params }),
}

export default CustomerAPI
