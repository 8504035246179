import areaApi from 'api/Area'
import { redirectToSelectArea } from 'utils/booking/common'
import { cancelReasonsActionsCreator } from 'store/toolkit/cancelReasons/cancelReasons.reducer'
import { currentAreaActionsCreator } from 'store/toolkit/currentArea/currentArea.reducer'
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
import PATHS from 'routers/paths'
// FOR ACTION REASONS


export const getActionReasons = (
  {
    scope, customerType, companyID, callback
  }
) => (dispatch, getState) => {
  const areaID = getState().currentArea.id
  const params = {
    id: areaID,
    scope,
    company_id: companyID > 0 ? companyID : '',
    customer_type: customerType
  }
  areaApi.actionReasons(areaID, params, (err, response) => {
    Promise.resolve(
      dispatch(cancelReasonsActionsCreator.areaUpdateCancelReasons(response?.data?.data))
    ).then(() => {
      callback(response.data.data, response.data.error)
    })
  })
}

export const actionReasonByArea = (
  {
    scope, areaID, customerType, companyID, callback
  }
) => (dispatch, getState) => {
  const params = {
    id: areaID,
    scope,
    company_id: companyID > 0 ? companyID : '',
    customer_type: customerType
  }
  areaApi.actionReasons(areaID, params, (err, response) => {
    Promise.resolve(
      dispatch(cancelReasonsActionsCreator.areaUpdateCancelReasons(response.data.data))
    ).then(() => {
      callback()
    })
  })

}


export const getCurrentAreaCustomer = (countryCode) => async (dispatch) => {
  const currentAreaIdCustomer = await areaApi.getCurrentArea({
    ...(countryCode && { country_code: countryCode }),
    only: ['id']
  })
  if (currentAreaIdCustomer) {
    dispatch(getAreaInfo(currentAreaIdCustomer))
  } else {
    if(window.location.pathname !== PATHS.SELECT_AREA) {
      redirectToSelectArea()
    }
  }
}

export const getAreaInfo = areaId => async (dispatch) => {
  const dataServiceArea = await areaApi.getServiceArea(areaId)
  const { object: objectArea, dropdown_areas: dropdownAreas, languages } = dataServiceArea
  dispatch(extraInfosActionsCreator.updateExtraInfos({
    ...objectArea,
    country_language: objectArea?.language || 'en',
    listLanguage: languages,
    dropdownAreas
  }))
  dispatch(currentAreaActionsCreator.setCurrentArea({
    ...objectArea,
    supported_zone: objectArea?.settings?.supported_zone || []
  }))
}
