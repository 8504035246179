import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Popover from '../Popover/index'
import useElBoundingRect from 'hooks/useElBoundingRect'
import I18n from 'i18n/i18n'

const ListFootNotes = ({
  anchorElRect, anchorRef, footNotes, showPopup, setShowPopup,
  screenHeight, positionRight, positionLeft, topContainerScroll,
  footerHeight, navbarHeight
}) => {

  const [maxHeightContent, setMaxHeightContent] = useState(600)

  const [refChildren, rectChildren, rerender] = useElBoundingRect()
  const closePopover = () => {
    if (showPopup) {
      setShowPopup(false)
    }
  }

  const clickOutside = (e) => {
    if (anchorRef.current && !anchorRef.current.contains(e.target)) {
      setShowPopup(false)
    }
  }

  useEffect(() => {
    const contextAvailableHeight = screenHeight - footerHeight - navbarHeight - 60
    if (contextAvailableHeight < maxHeightContent) {
      setMaxHeightContent(contextAvailableHeight)
    }
  }, [refChildren.height])

  useEffect(() => {
    rerender()
  }, [footNotes, maxHeightContent])

  return (
    <Popover
      id="popover-footnote"
      showPopover={showPopup}
      anchorElRect={anchorElRect}
      rectChildren={rectChildren}
      navbarHeight={navbarHeight}
      footerHeight={footerHeight}
      positionRight={positionRight}
      positionLeft={positionLeft}
      clickOutside={clickOutside}
      screenHeight={screenHeight}
      topContainerScroll={topContainerScroll}
    >
      <div className="footnote-select-wrapper" ref={refChildren}>
        <span id="close-popup-btn" className="BoxCustom-Close" onClick={closePopover}>
          <i className="b material-icons Icon">
            close
          </i>
        </span>
        <div className="footnote-select-inner">
          <div className="header">
            {I18n.t('webapp.foot_notes.additional_info')}
          </div>
          <div className="container" style={{ maxHeight: maxHeightContent }}>
            {footNotes.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="row" key={index}>
                <div className="heading">
                  {item.heading}
                </div>
                <div className="content">
                  {item.content}
                </div>
                {item.hyperlink_text && item.hyperlink && (
                  <a href={item.hyperlink} target="_blank" rel="noopener noreferrer">
                    {item.hyperlink_text}
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Popover>
  )
}

ListFootNotes.propTypes = {
  anchorElRect: PropTypes.shape({}).isRequired,
  anchorRef: PropTypes.shape({
    current: PropTypes.shape({
      contains: PropTypes.func
    })
  }).isRequired,
  footNotes: PropTypes.arrayOf(PropTypes.object).isRequired,
  showPopup: PropTypes.bool.isRequired,
  setShowPopup: PropTypes.func.isRequired,

  screenHeight: PropTypes.number,
  positionRight: PropTypes.number,
  positionLeft: PropTypes.number,
  topContainerScroll: PropTypes.number,
  footerHeight: PropTypes.number,
  navbarHeight: PropTypes.number,
}
ListFootNotes.defaultProps = {
  screenHeight: window.innerHeight,
  positionRight: 0,
  positionLeft: 0,
  topContainerScroll: 0,
  footerHeight: 56,
  navbarHeight: 76,
}

export default ListFootNotes
