import React from 'react'
import Button from '../Button/Button'
import ClosuresUtils from '../../../utils/booking/ClosuresUtils'
import { BOOKING_TYPE, HEADER_STATUS } from 'constants/frontpageConstants'
import { includes, isEmpty } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { LCL_BOOKING_ICON, LCL_CAR_ICON, LCL_SHIP_ICON } from 'constants/imageConstants'
import Utils from 'utils/Utils'
import { LCL_CUSTOMER_URL } from 'constants/appConstants'
import TallyInfo from 'components/booking_cards/TallyInfo'
import { STATUS_CS_FINDING_DRIVER, STATUS_DELIVERY_COMPLETED, STATUS_LOCATING_DRIVER } from 'constants/bookingConstants'
interface IBookingCardFP {
  id: number
  status: string
  lcl_booking_id: string
  booking_code: number
  company_id: number
  customer_id: number
  time_type?: string
  tally_info?: {
    original_estimate_values: number
  }
  is_ltl?: boolean
  waiting_for_customer_confirmation?: any
  driver: {
    id: number
    name: string
    phone: string
    fleet_partner_id: null
    fleet_partner_name: string
    rating: number
    driver_image_url: string
  }
  vehicle_type: {
    id: number
    name: string
    settings: {
      sending_eta_sms_period: number
    }
  }
  service_type: {
    name: string
  }
  locations: {
    name: string
  }[]
  vessel_info: {
    id: number
    name: string
    voyage_number: number
  }
  lcl_depot: {
    contact_name: string
    contact_phone: number
    name: string
  }
  spil_number: string
  vehicle: {
    number_passenger_seat: string
    plate_number: string
    vehicle_color: string
    vehicle_brand_name: string
    vehicle_make: string
    vehicle_model: string
  }
  fleet_partner?: any
  display_total_fees: number
  transit_time: string
  payment: number
  arrive_final_destination: string
  currency: string
  country_code: string
  area_language: string
  chat_enable: boolean
  media_chat_enable: boolean
  need_confirmation: boolean
  is_paid: boolean
  status_id: number
  total_distance: number
  pickup_time: string
  completed_at: number
  created_at: number
  updated_at: number
  lcl_reach_final_stop_by: string
  pay_before: string
  actual_departure: string
  vessel_departure: string
}
const BookingCard = ({
  booking,
  key,
  countryCode,
  specialClass,
}: {
  booking: IBookingCardFP
  key: number
  countryCode: string
  specialClass?: string
}) => {
  const { t } = useTranslation()

  const getType = () => {
    if (booking.lcl_booking_id) return BOOKING_TYPE.BOOKING_LCL
    if (booking.is_ltl) return BOOKING_TYPE.BOOKING_PTL
    return BOOKING_TYPE.BOOKING_FTL
  }
  const renderBookingToType = (
    bookingType: BOOKING_TYPE.BOOKING_FTL | BOOKING_TYPE.BOOKING_PTL | BOOKING_TYPE.BOOKING_LCL
  ) => {
    const acceptTallyStatuses = ['driver_accept_booking', 'fleet_accept_booking', 'delivery_in_progress']
    const isShowTally =
      includes(acceptTallyStatuses, booking.status) &&
      !isEmpty(booking.tally_info) &&
      !isEmpty(booking.tally_info?.original_estimate_values)
    const driver = booking.driver
    const fleetPartner = booking.fleet_partner
    switch (bookingType) {
      case BOOKING_TYPE.BOOKING_FTL:
      case BOOKING_TYPE.BOOKING_PTL:
        return (
          <div>
            <div
              key={key}
              className={`booking-card-container booking-card-${booking.time_type} ${
                booking.is_ltl ? 'booking-card-ltl' : ''
              } ${specialClass ? specialClass : ''}`}
            >
              <div className="flex">
                <img
                  src={ClosuresUtils.showBookingIcon(
                    booking.status,
                    booking.time_type,
                    booking.is_ltl,
                    booking.need_confirmation,
                    booking.waiting_for_customer_confirmation
                  )}
                  alt={`${booking.status}`}
                />
                <div className="booking-card__header">
                  <div className="mb3">{ClosuresUtils.showBookingStatusText(booking)}</div>
                  <div>
                    <span>{`${t(`booking_details.time_type.${booking.time_type}`)} `}</span>
                    <span>{` #${booking.id}`}</span>
                  </div>
                </div>
              </div>
              <hr />
              <div className="recent-card-content">
                <div>
                  <div className="flex flex-column booking-card__info">
                    {booking.status === STATUS_DELIVERY_COMPLETED && (
                      <div className="flex booking-card__status mb10">
                        {t('closures.label.complete')}:{' '}
                        <span>
                          {Utils.formatDateTime(booking?.completed_at * 1000, countryCode).fullTimeWithOutDate}
                        </span>
                      </div>
                    )}
                    {isShowTally ? <TallyInfo booking={booking} /> : null}
                    {[STATUS_LOCATING_DRIVER, STATUS_CS_FINDING_DRIVER].includes(booking.status) ? (
                      <p className="mt10">No driver info</p>
                    ) : (
                      <div className="flex driver-info">
                        <div>
                          <img src={driver?.driver_image_url || fleetPartner?.photo_url} alt="" />
                        </div>
                        <div>
                          <span>{booking.driver?.name || booking.fleet_partner?.name}</span>
                          <p>
                            {booking.vehicle
                              ? `${booking.vehicle_type?.name} ${
                                  booking.vehicle?.vehicle_brand_name ? ` - ${booking.vehicle?.vehicle_brand_name}` : ''
                                } ${booking.vehicle?.plate_number ? `- ${booking.vehicle?.plate_number}` : ''}`
                              : `${booking.vehicle_type?.name}`}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex booking-card-action">
                  <Button
                    onClick={async () => {
                      await window.Moengage.track_event('Select Progress On Booking Card Front Page')
                      window.location.href = `/bookings/${booking.id}/tracking`
                    }}
                    containerClassName="booking-card__button"
                    className={`booking-card-${booking.time_type}`}
                    buttonContent={
                      booking?.status === STATUS_DELIVERY_COMPLETED ? t('lcl.progress') : t('lcl.liveProgress')
                    }
                  />
                  <Button
                    onClick={async () => {
                      await window.Moengage.track_event('Select Booking Details On Booking Card Front Page')
                      window.location.href = `/bookings/${booking.id}`
                    }}
                    containerClassName="booking-card__button"
                    className={`booking-card-${booking.time_type}`}
                    buttonContent="Details"
                  />
                </div>
              </div>
            </div>
          </div>
        )
      case BOOKING_TYPE.BOOKING_LCL:
        const renderInfoContact = () => {
          switch (true) {
            case HEADER_STATUS.ASSIGNING === booking.status_id:
              return <p>{t('closures.label.no_driver_info')}</p>
            case [
              HEADER_STATUS.CONFIRMED,
              HEADER_STATUS.GOING_TO_PICKUP,
              HEADER_STATUS.AT_PICKUP,
              HEADER_STATUS.GOING_TO_DEPOT,
              HEADER_STATUS.AT_DEPOT,
              HEADER_STATUS.SUBMITTING_EDIT,
            ].includes(booking.status_id):
              return (
                <>
                  {booking.service_type?.name === 'D2D' ? (
                    <>
                      {isEmpty(driver) && isEmpty(fleetPartner) ? (
                        <></>
                      ) : (
                        <div className="driver-info">
                          <div>
                            <img src={driver?.driver_image_url || fleetPartner?.photo_url} alt="" />
                          </div>
                          <div>
                            <span>{driver?.name || fleetPartner?.name}</span>
                            <p>
                              {booking.vehicle
                                ? `${booking.vehicle_type?.name} ${
                                    booking.vehicle?.vehicle_brand_name
                                      ? `- ${booking.vehicle?.vehicle_brand_name}`
                                      : ''
                                  } ${booking.vehicle?.plate_number ? `- ${booking.vehicle?.plate_number}` : ''}`
                                : `${booking.vehicle_type?.name}`}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="flex tracking-content-depot">
                      <div>
                        <p className="mb5">{booking?.lcl_depot.name}</p>
                        <p className="mb5">{booking?.lcl_depot.contact_name}</p>
                        <p className="fw-normal mb0">{booking?.lcl_depot.contact_phone}</p>
                      </div>
                    </div>
                  )}
                </>
              )
            case [HEADER_STATUS.GOODS_ACCEPTANCE, HEADER_STATUS.AT_SEA, HEADER_STATUS.AT_DESTINATION_PORT].includes(
              booking.status_id
            ):
              return (
                <div className="flex align-items-center">
                  <div className="flex tracking-content-status-vessel">
                    <div>
                      <p className="mb5">SPIL</p>
                      <p className="mb5">
                        {booking.vessel_info?.id} {booking.vessel_info.voyage_number}
                      </p>
                      <div className="flex align-items-center">
                        <img width={16} height={16} src={LCL_SHIP_ICON} alt="" />
                        <p className="fw-normal mb0">{booking.vessel_info?.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            case [HEADER_STATUS.GOING_TO_DESTINATION, HEADER_STATUS.COMPLETED].includes(booking.status_id):
              return (
                <div className="status-spil">
                  <p className="mb5">SPIL</p>
                  <div className="flex">
                    <img className="mr10" src={LCL_CAR_ICON} alt="" />
                    <span>{t('closures.label.ground_services')}</span>
                  </div>
                </div>
              )
            default:
              break
          }
        }

        const renderInfo = () => {
          return (
            <div key={key} className={`booking-card-container booking-card-lcl ${specialClass ? specialClass : ''}`}>
              <div className="flex">
                <img src={LCL_BOOKING_ICON} alt={`${booking.status}`} />
                <div className="booking-card__header">
                  <div className="mb3">{t(`webapp.tracking.headerTitle.${booking?.status_id}`)}</div>
                  <div>
                    <span>{booking.service_type?.name === 'P2D' ? 'Port to Door' : 'Door to Door'}</span>
                    <span>{` #${booking.spil_number}`}</span>
                  </div>
                </div>
              </div>
              <hr />
              <div className="recent-card-content">
                <div>
                  {[HEADER_STATUS.COMPLETED].includes(booking.status_id) ? (
                    <div className="flex booking-card__status mb10">
                      {t('closures.label.complete')}:{' '}
                      <span>{Utils.formatDateTime(booking?.completed_at * 1000, countryCode).fullTimeWithOutDate}</span>
                    </div>
                  ) : (
                    <div className="flex booking-card__status mb10">
                      {t('webapp.tally.reach_final_stop_by')}:{' '}
                      <span className="ml5" title={booking?.arrive_final_destination}>
                        {booking?.arrive_final_destination}
                      </span>
                    </div>
                  )}
                  <div className="flex booking-card__info">{renderInfoContact()}</div>
                </div>
                <div className="flex booking-card-action">
                  <Button
                    onClick={async () => {
                      await window.Moengage.track_event('Select Progress On Booking Card Front Page')
                      window.location.href = `${LCL_CUSTOMER_URL}/tracking/${booking.lcl_booking_id}?is_open=${
                        booking.status_id === HEADER_STATUS.SUBMITTING_EDIT ? false : true
                      }`
                    }}
                    containerClassName="booking-card__button"
                    className={`booking-card-${booking.time_type}`}
                    buttonContent={
                      booking.status_id === HEADER_STATUS.COMPLETED ? t('lcl.progress') : t('lcl.liveProgress')
                    }
                  />
                  <Button
                    onClick={async () => {
                      await window.Moengage.track_event('Select Booking Details On Booking Card Front Page')
                      window.location.href = `${LCL_CUSTOMER_URL}/tracking/${booking.lcl_booking_id}`
                    }}
                    containerClassName="booking-card__button"
                    className={`booking-card-${booking.time_type}`}
                    buttonContent={t('lcl.details')}
                  />
                </div>
              </div>
            </div>
          )
        }
        return <div>{renderInfo()}</div>
      default:
        break
    }
  }
  return <>{renderBookingToType(getType())}</>
}

export default BookingCard
