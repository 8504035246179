import { useDispatch } from 'react-redux'
import { isEmpty, get, size } from 'lodash-es'
import PropTypes from 'prop-types'
import CommonUtils from 'utils/common'
import { useTranslation } from 'react-i18next'
import BatchMenu from '../common/BatchMenu'
import DevinaMenu from '../common/DevinaMenu'
import Menu from './Menu'
import GuestModals from '../new_booking/guest_flow/Modals'
import { ICON_FLAG_EN, IMAGE_PLACE_HOLDER, V2_LOGO_WHITE, V2_LOGO_WHITE_TRANSPORTIFY } from 'constants/imageConstants'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'
import { ALL_FLAG, AUTHORIZELOGIN } from 'constants/headerConstant'
import { TRANSPORTIFY_WEBAPP_HOST, DELIVEREE_WEBAPP_HOST } from 'constants/appConstants'
import { getCompanyByEmployId, getCurrentEmploy } from 'utils/booking/common'
import { Link, useNavigate } from 'react-router-dom'
import paths from 'routers/paths'

const Header = (props) => {
  const dispatch = useDispatch()
  const {
    customerSignedIn,
    currentCustomer,
    isBusinessSession,
    dropdownAreas,
    listLanguage,
    area,
    extraInfos,
    linkSetting,
    employs,
  } = props
  const { t, i18n } = useTranslation()
  const { termsAndConditionsLink, privacyPolicyLink, hideAreaSelector } = linkSetting
  const logo = CommonUtils.isTransportify() ? V2_LOGO_WHITE_TRANSPORTIFY : V2_LOGO_WHITE

  const lastLoginEmployId = get(currentCustomer, 'last_login_employ_id', null)
  const companies = get(currentCustomer, 'companies', [])
  let currentEmploy = {}
  let company = {}
  if (!isEmpty(employs) && isBusinessSession) {
    currentEmploy = getCurrentEmploy(employs, lastLoginEmployId)
    company = getCompanyByEmployId(companies, currentEmploy.company_id)
  } else {
    currentEmploy = { ...currentCustomer }
  }

  const setFlag = (country) => ALL_FLAG[country]

  const showProfilePanel = () => {
    dispatch(currentCustomerActionsCreator.toggleProfilePanel(true))
  }

  const handelChangeLanguage = (language) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('lang', language);
    window.location.search = urlParams;
  }

  const handleReturnImage = (image) => {
    if (image && image !== 'missing.png') {
      return image
    }
    return IMAGE_PLACE_HOLDER
  }

  const onClickArea = (countryCode, areaId) => {
    const country = countryCode.toLowerCase()
    let href = `${DELIVEREE_WEBAPP_HOST}?area_id=${areaId}`
    if((country === 'ph' || country === 'philippines') && TRANSPORTIFY_WEBAPP_HOST) {
      href = `${TRANSPORTIFY_WEBAPP_HOST}?area_id=${areaId}`
    }
    window.location.href = href
  }

  const renderAreas = () => (
    <div className="Menu-Nav-Item">
      <div className="dark-green Dropdown">
        <div className="Dropdown-Close" />
        <div className="Dropdown-Head">
          <div className="Dropdown-Head-Item">
            <div className="TitleSubtitle">
              <div className="TitleSubtitle-title White">{area?.name}</div>
            </div>
          </div>
          <div className="Dropdown-Head-Caret">
            <i className="xb material-icons Icon">arrow_drop_down</i>
          </div>
        </div>
        <div className="top Dropdown-Head-Arrow" />
        <div className="right top Dropdown-Menu">
          {dropdownAreas.map((item) => {
            if (size(item.areas) > 1) {
              return (
                <div className="Dropdown-Menu-Item" key={item.country}>
                  {item.country}
                  <ul className="Dropdown-Menu-Item-Sub">
                    {item.areas.map((it) => (
                      <li key={it.id}>
                        <a
                          className={it.id === area.id ? 'selected' : ''}
                          rel="nofollow"
                          data-method="put"
                          onClick={() => onClickArea(item.country_code || item.country, it.id)}
                        >
                          {it.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }
            return (
              <a
                key={item.country}
                className={`Dropdown-Menu-Item Font-Size ${item.areas[0].id === area.id ? 'selected' : ''}`}
                rel="nofollow"
                onClick={() => onClickArea(item.country_code || item.country, item.areas[0].id)}
              >
                {item.country}
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )

  const renderSwitchLanguages = () => {
    const activeLanguage = CommonUtils.getLanguage() || 'en'
    return (
      <div className="Menu-Nav-Item Menu-Nav-Item-Flag">
        {!isEmpty(listLanguage) &&
          listLanguage.map(
            (item) =>
              item !== 'en' &&
              item !== 'vi' && (
                <a
                  key={item}
                  className={activeLanguage === item ? 'active' : ''}
                  rel="nofollow"
                  data-method="put"
                  onClick={() => handelChangeLanguage(item)}
                >
                  <img src={setFlag(item)} alt={item} />
                </a>
              )
          )}
        <a
          className={activeLanguage === 'en' ? 'active' : ''}
          rel="nofollow"
          data-method="put"
          onClick={() => handelChangeLanguage('en')}
        >
          <img src={ICON_FLAG_EN} alt="En" />
        </a>
      </div>
    )
  }

  const renderAvatar = () => {
    if (customerSignedIn) {
      const image = currentEmploy.company?.photo_url
      if (isBusinessSession && !isEmpty(image)) {
        return (
          <div className="Menu-Nav-Item">
            <div className="rounded Avatar" onClick={showProfilePanel}>
              <img alt={currentEmploy.company?.name} src={handleReturnImage(currentEmploy.company?.photo_url)} />
            </div>
          </div>
        )
      }
      if (!isBusinessSession) {
        return (
          <div className="Menu-Nav-Item">
            <div className="rounded Avatar" onClick={showProfilePanel}>
              <img alt={currentCustomer.name} src={handleReturnImage(currentCustomer.avatar_square)} />
            </div>
          </div>
        )
      }
    }
    return null
  }

  const renderButton = (name, url) => (
    <div className="Menu-Nav-Item Menu-Nav-Item-Button">
      <a className="white-text white-border Button" href={url}>
        {name}
      </a>
    </div>
  )

  const renderButtonAction = () => {
    const lang = i18n.language
    return (
      <>
        {renderButton(t('devise_template.sign_in'), `/customers/sign_in?lang=${lang}`)}
        {renderButton(t('devise_template.sign_up'), `/customers/sign_up?lang=${lang}`)}
      </>
    )
  }

  return (
    <div className="container-top-nav header-bar">
      <div className="Menu Top-Nav fixed fixed-width">
        <div className="Logo cur-pointer">
          {isBusinessSession && (
            <div className="Logo-business-badge">
              For
              <br />
              Business
            </div>
          )}
          <a
            href="/bookings/new"
            onClick={() => {
              window.location.href = paths.NEW_BOOKING_SINGLE
            }}
            className={isBusinessSession ? 'logo-link' : 'flex'}
          >
            <img src={logo} alt="Logo deliveree white" role="presentation" />
          </a>
        </div>
        <div className="Menu-Nav left">
          <Menu
            customerSignedIn={customerSignedIn}
            isBusinessSession={isBusinessSession}
            currentCustomer={currentCustomer}
            listLanguage={listLanguage}
            currentEmploy={currentEmploy}
            company={company}
          />
          {customerSignedIn && (
            <>
              <div className="Menu-Nav-Item Devina-Screen">
                <DevinaMenu currentCustomer={currentCustomer} extraInfos={extraInfos || {}}/>
              </div>
              <div className="Menu-Nav-Item Devina-Screen Devina-Screen-Batchs">
                <BatchMenu />
              </div>
            </>
          )}
        </div>
        <div className="Menu-Nav right">
          {!hideAreaSelector && renderAreas()}
          {renderSwitchLanguages()}
          {renderAvatar()}
          {!customerSignedIn && renderButtonAction()}
        </div>
      </div>
      {extraInfos?.area_id && (
        <GuestModals
          authorizeLogin={AUTHORIZELOGIN}
          termsAndConditionsLink={termsAndConditionsLink}
          privacyPolicyLink={privacyPolicyLink}
          extraInfos={extraInfos}
          currentCustomer={currentCustomer}
        />
      )}
    </div>
  )
}

Header.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  customerSignedIn: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  area: PropTypes.shape({}).isRequired,
  listLanguage: PropTypes.arrayOf(PropTypes.string),
  isBusinessSession: PropTypes.bool.isRequired,
  linkSetting: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  employs: PropTypes.array,
}

export default Header
