import moment from 'moment'
import _ from 'lodash'
import $ from 'jquery'

import { TRACKING_UPDATE_DRIVER_DURATION_INTERVAL } from 'constants/trackingBookingConstants'
import {
  NOT_SAME_PERCENT
} from '../constants/common/batchConstants'
import {
  STATUS_DRIVER_ACCEPT_BOOKING,
  STATUS_DELIVERY_IN_PROGRESS,
  STATUS_FLEET_ACCEPT_BOOKING,
  QUICK_CHOICE,
  FULL_DAY,
  SCHEDULE,
  NOW
} from 'constants/bookingConstants'
import I18n from 'i18n/i18n'
import StorageKeys from 'constants/storage-keys'

const CommonUtils = {
  playSound: (url, loopCount) => {
    const audio = document.createElement('audio')
    audio.style.display = 'none'
    audio.src = url
    audio.autoplay = true
    let count = loopCount
    audio.onended = () => {
      count -= 1
      if (count < 1) {
        audio.remove() // Remove after played.
      } else {
        audio.play()
      }
    }
    document.body.appendChild(audio)
  },
  createTmpIframe: (url) => {
    const ifrm = document.createElement('iframe')
    ifrm.setAttribute('id', 'ifrm') // assign id
    document.body.appendChild(ifrm) // to place at end of document
    ifrm.setAttribute('src', url) // assign src
    ifrm.setAttribute('frameborder', 0)
    ifrm.setAttribute('border', 0)
    ifrm.setAttribute('cellspacing', 0)
    ifrm.setAttribute('style', 'border-style: none;width: 0; height: 0; display: none;')
  },
  appName: () => {
    const arrTransportify = [
      'webapp.transportify.com.ph',
      'webapp.stg.transportify.com.ph',
      'webapp.test.transportify.com.ph'
    ]
    return _.includes(arrTransportify, window.location.hostname) ? I18n.t('app.transportify') : I18n.t('app.deliveree')
  },
  isTransportify: () => {
    const hostNames = [
      'webapp.transportify.com.ph',
      'webapp.stg.transportify.com.ph',
      'webapp.test.transportify.com.ph'
    ]
    return _.includes(hostNames, window.location.hostname)
  },
  formatMaskedPhone: (phone) => {
    const items = phone.split(',')
    if (items.length > 1) {
      return `${items.join(` (${I18n.t('webapp.preference_driver.phone_ext')}`)})`
    }
    return phone
  },
  getUpdateDriverDurationInterval: (duration) => {
    const fitnessInterval = _.find(TRACKING_UPDATE_DRIVER_DURATION_INTERVAL, item => item.duration <= duration)
    if (!_.isUndefined(fitnessInterval)) {
      return fitnessInterval.interval
    }
    return TRACKING_UPDATE_DRIVER_DURATION_INTERVAL[0].interval
  },
  shouldShowDriverEstimation: (bookingStatus, pickupTime, sendingEtaSmsPeriod) => {
    switch (bookingStatus) {
      case STATUS_DELIVERY_IN_PROGRESS:
        return true
      case STATUS_DRIVER_ACCEPT_BOOKING:
      case STATUS_FLEET_ACCEPT_BOOKING: {
        return moment().isSameOrAfter(moment(pickupTime, 'X').subtract(sendingEtaSmsPeriod, 'hour'))
      }
      default:
        return false
    }
  },
  converImageToBase64Url: (img) => {
    function toDataURL(url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.onload = () => {
        const reader = new FileReader()
        reader.onloadend = () => {
          callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    }
    return new Promise((resolve) => {
      toDataURL(img, (dataUrl) => {
        resolve(dataUrl)
      })
    })
  },
  detectSmallLaptop: () => {
    if ($(window).outerWidth() <= 1366) {
      return true
    }
    return false
  },
  replaceTextWithDotted: (name, config) => {
    if (_.size(name) > config.length) {
      return `${name.substring(0, config.length)}${config.dotted}`
    }
    return name
  },
  getTwilloChatToken: () => {
    const twilloChatToken = $('#twillo_chat_token').val()
    return twilloChatToken
  },
  moengageUserAttribute: (customerId, firstName, lastName, userName, email, phone,
    companyID, countryCode, isLogin, currentStep) => {
    const companyId = companyID || 'N/A'
    const switchAccountId = localStorage.getItem('switch_account')
    const id = companyID ? `${customerId}_${companyID}` : customerId
    if (switchAccountId) {
      localStorage.removeItem('switch_account')
    }
    window.Moengage.add_unique_user_id(id)
    window.Moengage.add_first_name(firstName)
    window.Moengage.add_last_name(lastName)
    window.Moengage.add_user_name(userName)
    window.Moengage.add_email(email)
    window.Moengage.add_mobile(phone)
    window.Moengage.add_user_attribute('Company ID', companyId)
    window.Moengage.add_user_attribute('Country Code', countryCode)
    window.Moengage.add_user_attribute('Is Logined', true)
    window.Moengage.add_user_attribute('Language', I18n.language)
    // window.Moengage.add_user_attribute('Credit Amount', creditAmount)
    if (isLogin || switchAccountId) {
      if (switchAccountId) {
        window.Moengage.track_event('User Login')
      } else {
        window.Moengage.track_event('User Login', {
          'At Step': CommonUtils.getAtStepAttributeMoengage(currentStep)
        })
      }
    }
  },
  getIsEnabledChatWithFleet(booking) {
    const fleetBeta = booking.fleet_partner?.beta || false
    const checkBetaChatWithFleet = fleetBeta ? STATUS_FLEET_ACCEPT_BOOKING : ''
    const listCheckBpChatWithFleet = [checkBetaChatWithFleet, STATUS_DRIVER_ACCEPT_BOOKING, STATUS_DELIVERY_IN_PROGRESS]

    return listCheckBpChatWithFleet.includes(booking.status)
  },
  removeFileExtension: (fileName) => {
    if (fileName) {
      const splitName = fileName.split('.')
      if (splitName.length > 1) {
        splitName.pop()
      }
      return splitName.join('.')
    }
    return ''
  },
  handleTrackMoengage: (data, currentStep) => {
    if (data) {
      const {
        id, last_name: lastName, first_name: firstName,
        name, email, phone, current_company_id: companyID,
        country_code: countryCode
      } = data
      const removePlusInPhone = phone && phone.slice(1)
      CommonUtils.moengageUserAttribute(id, firstName, lastName, name, email,
        removePlusInPhone, companyID, countryCode, true, currentStep)
    }
  },
  handleTrackTimeTypeMoengage: (timetype) => {
    switch (timetype) {
      case QUICK_CHOICE:
      case NOW:
        window.Moengage.track_event('Quick Choices Selected')
        break
      case SCHEDULE:
        window.Moengage.track_event('Schedule Time Type Selected')
        break
      case FULL_DAY:
        window.Moengage.track_event('Full Day Time Type Selected')
        break
      default: break
    }
  },
  setLanguage: (lang) => {
    localStorage.setItem(StorageKeys.LANGUAGE, lang)
  },
  getLanguage: () =>{
    return localStorage.getItem(StorageKeys.LANGUAGE)
  },
  getAtStepAttributeMoengage: (currentStep) => {
    let result = ''
    switch (currentStep) {
      case 0:
        result = 'nav bar (web)'
        break
      case 2:
        result = 'favorite drivers'
        break
      case 3:
        result = 'FTL step 3'
        break
      default: break
    }
    return result
  },
  getMoengageId: (currentCustomer, id) => {
    if (currentCustomer.last_login_employ_id) {
      if (id === 0) {
        // Case bp to nonbp
        return {
          oldId: `${currentCustomer.id}_${currentCustomer.last_login_employ_id}`,
          newId: currentCustomer.id
        }
      }
      // Case bp to bp
      return {
        oldId: `${currentCustomer.id}_${currentCustomer.last_login_employ_id}`,
        newId: currentCustomer.id === id ? id : `${currentCustomer.id}_${id}`
      }
    }
    // Case non-bp to bp
    return {
      oldId: currentCustomer.id,
      newId: `${currentCustomer.id}_${id}`
    }
  },
  getCashBackPercent: (bookings) => {
    let cashBackPercent = null
    // get list cashback not null
    const listCashBackNotNull = bookings.filter(val => val.cash_back_reward)
    const isAllEnoughData = bookings.every(val => val.enough_data_check_cashback)
    if (listCashBackNotNull.length > 0 && isAllEnoughData) {
      const firstCashBackPercent = listCashBackNotNull[0]?.cash_back_reward
      cashBackPercent = listCashBackNotNull.every(
        booking => booking.cash_back_reward === firstCashBackPercent
      ) ? firstCashBackPercent : NOT_SAME_PERCENT
    }
    return cashBackPercent
  },
  checkCashbackUpdate: (index, prevBooking, bookings) => {
    const isChangeLocation = !_.isEqual(
      prevBooking[index]?.locations,
      bookings[index]?.locations
    )
    const isChangeTimeType = !_.isEqual(
      prevBooking[index]?.time_type_option,
      bookings[index]?.time_type_option
    )
    const isChangeVehicleTypeId = !_.isEqual(
      prevBooking[index]?.vehicle_type,
      bookings[index]?.vehicle_type
    )
    return isChangeLocation || isChangeTimeType || isChangeVehicleTypeId
  },
  isSingle: () => ['/', '/bookings', '/bookings/new'].includes(window.location.pathname),
  isMultiple: () => ['/bookings/multiple'].includes(window.location.pathname),
  isBatchEZ: () => ['/batches/ez_spread_sheet'].includes(window.location.pathname),
  isSmartLoad: () => ['/batches/smart_load_planner'].includes(window.location.pathname),
  goToNewLocation: (path) => {window.location.href = path}
}


export default CommonUtils